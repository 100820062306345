import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import {
  BakingBox,
  Excerpt,
  ImageWrapper,
  SeperatorBar,
  Tags,
  Title,
} from "./BakingCardElements"
import PropTypes from "prop-types"

function BakingCard({ image, title, height, width }) {
  return (
    <>
      <BakingBox>
        <ImageWrapper>
          <GatsbyImage
            image={image}
            alt={title}
            width={width}
            height={height}
            objectPosition={"left top"}
            imgStyle={{borderRadius: "10px 10px 0 0"}}
          />
        </ImageWrapper>
        <Title>{title}</Title>
      </BakingBox>
    </>
  )
}

//<Tags>Tags: {tags.join(", ")}</Tags>

BakingCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  alt: PropTypes.string,
  description: PropTypes.string,
}

export default BakingCard
