import styled from "styled-components"
import { device } from "../../../devices"

export const LightboxOverlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const LightboxContainer = styled.div`
  background-color: #222;
  padding: 10px;
  height: 100vh;
  box-sizing: border-box;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: ${props => props.height + "px"};
  }
`

export const CloseButtonContainer = styled.div`
  font-size: 40px;
  height: 45px;
  display: block;
`

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
  width: min-content;
  position: absolute;
  right: calc(100% / 2 - (20vw + 90px + 5px) - 80px);
  padding-top: 10px;

  @media ${device.mobile} {
    right: 20px;
    padding-top: 5px;
  }
`

export const LightboxImageSection = styled.div`
  display: inline-block;
`

export const LightboxImageAndButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LightboxMainImageWrapper = styled.div`
  width: 40vw;
  height: 40vw;
  margin: auto;

  @media ${device.mobile} {
    width: 70vw;
    height: 70vw;
  }
`

export const AdvanceImageButton = styled.div`
  font-size: 3rem;
  color: white;
  padding: 12px 12px;
  cursor: pointer;
`

export const ThumbnailImageGrid = styled.div`
  grid-template-columns: repeat(auto-fit, 3.5vw);
  display: grid;
  padding: 10px;
  grid-gap: 10px;
  overflow: hidden;
  justify-content: space-evenly;
  justify-items: center;

  @media ${device.mobile} {
    grid-template-columns: repeat(auto-fit, 10vw);
  }
`

export const ThumbnailImageWrapper = styled.div`
  width: 3.5vw;
  height: 3.5vw;
  border: ${props =>
    props.isActive ? "2px solid aqua" : "2px solid transparent"};
  display: inline-block;
  margin: auto;
  cursor: ${props => (props.isActive ? "auto" : "pointer")};

  &:hover {
    border: 2px solid aqua;
  }

  @media ${device.mobile} {
    width: 10vw;
    height: 10vw;
  }
`
export const InfoContainer = styled.div`
  color: white;
  width: 260px;
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  transition: 0.2s;

  @media ${device.mobile} {
    width: 95%;
    padding: 20px 0;
    height: 30vh;
    overflow: scroll;
  }
`
export const ItemTitle = styled.h2`
  font-weight: lighter;
  font-size: 2rem;
  margin-bottom: 20px;
`

export const SeperatorBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 20px 0;
`

export const ItemDescription = styled.p`
  font-size: 1.2rem;
  font-weight: lighter;
`

export const RecipeLink = styled.a`
  text-decoration: underline;
  color: white;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
  }
`
