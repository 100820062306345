import React from "react"

import BakingCard from "../BakingCard"
import { BakingCardGrid } from "./BakingGridElements"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import PropTypes from "prop-types"

function BakingGrid({ items, onItemClick }) {
  const data = useStaticQuery(graphql`
    query CakesQuery {
      allCakesJson {
        edges {
          node {
            img {
              src {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 600
                    height: 600
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            name
            id
            description
          }
        }
      }
    }
  `)

  const cakes = data.allCakesJson.edges

  return (
    <>
      <BakingCardGrid>
        {items.map((item, index) => (
          <div onClick={() => onItemClick(index)} key={"card" + index}>
            <BakingCard
              image={getImage(
                item.node.img.src.childImageSharp.gatsbyImageData
              )}
              title={item.node.name}
              description={item.node.description}
              key={item.node.id}
              height={200}
              width={200}
            />
          </div>
        ))}
      </BakingCardGrid>
    </>
  )
}

export default BakingGrid

BakingGrid.propTypes = {
  items: PropTypes.any,
  onItemClick: PropTypes.func,
}
