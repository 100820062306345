import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  BakingPageSectionHeader,
  BakingSectionParallax,
  BakingSectionTextSection,
  BakingSubheader,
  BakingText,
} from "../BakingPageElements/BakingPageElements"

import BakingCard from "../BakingCard"
import BakingGrid from "../BakingGrid"
import Lightbox from "../../Lightbox"
import BakingLightbox from "../BakingLightbox"

function BakingCategorySection({
  parallaxImage,
  parallaxPosition,
  header,
  subheader,
  description,
  items,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  function onItemClick(index) {
    setCurrentImage(index)
    setIsOpen(true)
  }

  return (
    <>
      <BakingLightbox
        items={items}
        linkText={"Recipe"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
      />
      <BakingSectionParallax image={parallaxImage} position={parallaxPosition}>
        <BakingPageSectionHeader>{header}</BakingPageSectionHeader>
        <BakingSectionTextSection>
          <BakingSubheader>{subheader}</BakingSubheader>
          <BakingText>{description}</BakingText>
        </BakingSectionTextSection>
      </BakingSectionParallax>
      <BakingGrid items={items} onItemClick={onItemClick} />
    </>
  )
}

BakingCategorySection.propTypes = {
  parallaxImage: PropTypes.any,
  parallaxPosition: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.any,
}

export default BakingCategorySection
