import styled from "styled-components"
import { Link } from "gatsby"

export const BakingBox = styled.div`
  padding-bottom: 10px;
  margin: 0 auto;
  background-color: rgb(0, 153, 153);
  transition: 0.2s;
  text-decoration: none;
  text-align: left;
  border-radius: 10px;
  display: block;
  backface-visibility: hidden; /* Fix Scaling Issue */
  color: #fff;

  &:hover {
    box-shadow: 0px 6px 12px 0px rgb(0, 0, 0, 0.6);
    scale: 1.05;
  }
`
//rgb(0, 153, 153)
//rgb(115, 32, 135)

export const ImageWrapper = styled.div`
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`

export const Title = styled.h3`
  font-weight: lighter;
  margin-top: 8px;
  text-align: center;
  font-size: 1.3rem;
`
