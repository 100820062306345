import React from "react"
import Layout from "../components/layout"

import BakingCategorySection from "../components/BakingComponents/BakingCategorySection"
import SEO from "../components/seo"

import cakeImage from "../images/baking-images/NotACake.jpeg"
import cookieImage from "../images/baking-images/BlackAndWhiteHorizontal.jpeg"
import { graphql } from "gatsby"

const BakingPage = ({ data }) => (
  <div>
    <Layout activePage="baking">
      <BakingCategorySection
        parallaxImage={cakeImage}
        parallaxPosition={"top"}
        header={"Cakes"}
        subheader={"Baking Towards Perfection"}
        description={`Cakes and other foods are difficult to perfect because they demand both an excellent taste and a flawless appearance. Visually, cakes in particular often go beyond a simple geometric pattern and attempt to capture a concept or even a story. The best cakes require a careful application of baking and decorating techniques as well as the necessary equipment and technology. To apply machine precision to my cakes, I make innovative use of 3d printing to create stencils for patterns and images. Each cake in the section is an attempt to refine my skills or to try a new recipe, technique, or flavor.
        `}
        items={data.allCakesJson.edges}
      />
      <BakingCategorySection
        parallaxImage={cookieImage}
        parallaxPosition={"center"}
        header={"The Rest"}
        subheader={"The Sea of Recipes"}
        description={`One of my favorite parts of baking is the almost endless variety of different items that can be baked and the even greater number of recipes and variations to try. While I do have a few favorite recipes that I frequently make, I also like to try new recipes and experiment with different flavors and techniques. Baking can also be a great way to explore and celebrate different cultures, seasons, and holidays.`}
        items={data.allMiscJson.edges}
      />
    </Layout>
  </div>
)

export const Head = () => (
  <SEO
    title="Baking"
    description="A gallery of my cakes and other baked goods. See my caramel apple themed cake, choclate babka, and more!"
  />
)

export default BakingPage

export const data = graphql`
  query CardsQuery {
    allCakesJson(filter: { name: { ne: "Sample Entry" } }) {
      edges {
        node {
          img {
            src {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 1000
                  height: 1000
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          recipeLinks
          recipeTitles
          name
          id
          description
        }
      }
    }

    allMiscJson(filter: { name: { ne: "Sample Entry" } }) {
      edges {
        node {
          img {
            src {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 1000
                  height: 1000
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          recipeLinks
          recipeTitles
          name
          id
          description
        }
      }
    }
  }
`
