import styled from "styled-components"
import { device } from "../../../../devices"

export const BakingPageSectionHeader = styled.h1`
  font-size: 8rem;
  font-weight: 300;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 100px;

  @media ${device.mobile} {
    font-size: 5rem;
  }

  @media ${device.tablet} {
    font-size: 6rem;
  }
`

export const BakingSectionParallax = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${props => props.image}) 25% 30% no-repeat;
  height: calc(100vh - 74px + 300px + 70vh - 74px + 20px);
  padding-top: 30vh;

  background-attachment: fixed;
  background-position: ${props => props.position};
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    height: calc(100vh - 74px + 600px + 70vh - 74px + 20px);
  }

  @media ${device.mobile} {
    height: calc(20vh - 74px + 700px + 70vh - 74px + 20px);
    background-attachment: initial;
  }

  // fixed background positioning is not supported on mobile browsers
  @media (hover: none) {
    background-attachment: initial;
  }

`

export const BakingSectionTextSection = styled.div`
  margin-top: calc(70vh - 74px - 100px + 10px);
  width: 100%;
  height: 300px;
  background: rgb(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media ${device.mobile} {
    height: 700px;
  }

  @media ${device.tablet} {
    height: 600px;
  }
`

export const BakingSubheader = styled.h2`
  font-size: 2rem;
  font-weight: lighter;
  text-align: center;
  padding: 0px 10px;

  @media ${device.tablet} {
    font-size: 2.5rem;
  }
`

export const BakingText = styled.p`
  font-size: 1.1rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media ${device.mobile} {
    font-size: 1.2rem;
    width: 85%;
  }

  @media ${device.tablet} {
    font-size: 1.4rem;
  }
`
