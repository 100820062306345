import styled from "styled-components"
import { device } from "../../../../devices"

export const BakingCardGrid = styled.div`
  padding: 60px 4vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 6vh;
  grid-column-gap: 6vw;
  width: 100%;
  align-self: stretch;
  box-sizing: border-box;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`
