import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  LightboxOverlay,
  LightboxMainImageWrapper,
  LightboxContainer,
  LightboxImageSection,
  AdvanceImageButton,
  LightboxImageAndButtonsContainer,
  ThumbnailImageGrid,
  ThumbnailImageWrapper,
  CloseButtonContainer,
  CloseButtonWrapper,
  InfoContainer,
  ItemTitle,
  ItemDescription,
  RecipeLink,
  SeperatorBar,
} from "./LightboxElements"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaRegTimesCircle } from "react-icons/fa"
import { IconContext } from "react-icons/lib"

function Lightbox({
  children,
  items,
  isOpen,
  setIsOpen,
  currentImage,
  setCurrentImage,
}) {
  function closeLightbox() {
    setIsOpen(false)
  }

  function advanceImage(increment) {
    let itemCount = items.length
    // advance image and wrap over if necessary
    setCurrentImage(
      (((currentImage + increment) % itemCount) + itemCount) % itemCount
    )
  }

  function setImageNum(index) {
    setCurrentImage(index)
  }

  return (
    <div>
      <LightboxOverlay display={isOpen ? "flex" : "none"}>
        <LightboxContainer height="100%">
          <CloseButtonContainer>
            <CloseButtonWrapper>
              <IconContext.Provider value={{ color: "fff" }}>
                <FaRegTimesCircle onClick={() => closeLightbox()} />
              </IconContext.Provider>
            </CloseButtonWrapper>
          </CloseButtonContainer>
          <InfoContainer>{children}</InfoContainer>
          <LightboxImageSection>
            <LightboxImageAndButtonsContainer>
              <AdvanceImageButton onClick={() => advanceImage(-1)}>
                &#10094;
              </AdvanceImageButton>
              <LightboxMainImageWrapper>
                <GatsbyImage
                  image={
                    items[currentImage].node.img.src.childImageSharp
                      .gatsbyImageData
                  }
                  alt={items[currentImage].node.name}
                  width={200}
                  height={200}
                  objectPosition={"left top"}
                />
              </LightboxMainImageWrapper>
              <AdvanceImageButton onClick={() => advanceImage(1)}>
                &#10095;
              </AdvanceImageButton>
            </LightboxImageAndButtonsContainer>
            <ThumbnailImageGrid>
              {items.map((item, index) => (
                <ThumbnailImageWrapper
                  isActive={index === currentImage}
                  onClick={() => setImageNum(index)}
                  key={"TIW" + index}
                >
                  <GatsbyImage
                    image={
                      items[index].node.img.src.childImageSharp.gatsbyImageData
                    }
                    alt={items[index].node.name}
                    width={200}
                    height={200}
                    objectPosition={"left top"}
                    key={"LGI" + index}
                  />
                </ThumbnailImageWrapper>
              ))}
            </ThumbnailImageGrid>
          </LightboxImageSection>
        </LightboxContainer>
      </LightboxOverlay>
    </div>
  )
}

export default Lightbox

Lightbox.propTypes = {
  items: PropTypes.any,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  currentImage: PropTypes.number,
  setCurrentImage: PropTypes.func,
}
