import React from "react"
import Lightbox from "../../Lightbox"
import PropTypes from "prop-types"
import {
  ItemTitle,
  SeperatorBar,
  ItemDescription,
  RecipeLink,
} from "./BakingLightboxElements"

function RecipeComponents({ items, index }) {
  let item = items[index]
  // No recipe links
  if (item.node.recipeLinks == null) {
    return <></>
  }

  // One recipe link
  else if (
    item.node.recipeLinks.length === 1 &&
    item.node.recipeTitles[0] === "main"
  ) {
    return (
      <>
        <br />
        <RecipeLink href={item.node.recipeLinks[0]}>Recipe</RecipeLink>
      </>
    )
  }

  // Multiple recipe links
  else {
    return (
      <ItemDescription>
        <br />
        Recipes:
        {item.node.recipeLinks.map((link, index) => (
          <>
            {" "}
            <RecipeLink href={link}>{item.node.recipeTitles[index]}</RecipeLink>
            {index < item.node.recipeLinks.length - 1 ? "," : ""}
          </>
        ))}
      </ItemDescription>
    )
  }
}

function InfoSection({ items, index }) {
  let item = items[index]
  return (
    <>
      <ItemTitle>{item.node.name}</ItemTitle>
      <SeperatorBar />
      <ItemDescription>{item.node.description}</ItemDescription>
      <RecipeComponents items={items} index={index} />
    </>
  )
}

function BakingLightbox({
  items,
  isOpen,
  setIsOpen,
  currentImage,
  setCurrentImage,
}) {
  return (
    <>
      <Lightbox
        items={items}
        linkText={"Recipe"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
      >
        <InfoSection items={items} index={currentImage} />
      </Lightbox>
    </>
  )
}

export default BakingLightbox

BakingLightbox.propTypes = {
  items: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  currentImage: PropTypes.number,
  setCurrentImage: PropTypes.func,
}
