import styled from "styled-components"

export const ItemTitle = styled.h2`
  font-weight: lighter;
  font-size: 2rem;
  margin-bottom: 20px;
`

export const SeperatorBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 20px 0;
`

export const ItemDescription = styled.p`
  font-size: 1.2rem;
  font-weight: lighter;
`

export const RecipeLink = styled.a`
  text-decoration: underline;
  color: white;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
  }
`
